import React, { useEffect, useState } from "react";
import images from "../../../Assets/Images";
import { useNavigate } from "react-router-dom";
import Auth from "../../../helpers/Auth";

export default function FillTheGap({ chunk }) {
  const navigate = useNavigate();
  const [filGap, setFillGap] = useState();
  const [state, setState] = useState({
    click_here: true,
    check: false,
  });
  const [response, setResponse] = useState([]);
  const [displayText, setDisplayText] = useState("");
  const typingSpeed = 10;
  const { http, user } = Auth();
  useEffect(() => {
    let content = "";

    if (typeof chunk === "object") {
      content = chunk[0];
    } else {
      content = JSON.parse(chunk)[0];
    }
    // console.log(content);
    setFillGap(content);
  }, [chunk]);
  const fill_the_gap = (type, param) => {
    if (state.click_here) return;
    if (response.length < 2) {
      setResponse([...response, [param]]);
    }
  };

  const checkResponse = () => {
    // console.log(filGap.gaps, response);
    if (response.length > 0) {
      if (
        filGap.gaps[0] == response[0][0] &&
        filGap.gaps[1] == response[1][0]
      ) {
        setState({ ...state, is_res_correct: true, check: true });
        let index = -1;
        const intervalId = setInterval(() => {
          setDisplayText((prev) => prev + filGap.answer.charAt(index));
          index++;

          if (index === filGap.answer.length) {
            clearInterval(intervalId); // Clear the interval when the text is fully typed
          }
        }, typingSpeed);
        // console.log("true");
      } else {
        setState({ ...state, is_res_correct: false, check: true });
        // console.log("f");
      }
    }

    // console.log(filGap.gaps, response);
  };
  const tryAgain = () => {
    setState({ click_here: true, check: false });
    setResponse([]);
  };

  const toContinue = async () => {
    let result = await http.post(`/onboarding-user-status`, {
      user_id: user.id,
      // onboarding_id:1
      status_update: "true",
    });
    navigate("/onboarding/v2/congrats");
  };
  return (
    <>
      <div class="chat_container">
        <div class="chat_header">
          <h3>{filGap && filGap.title}</h3>
          <p>{filGap && filGap.description}</p>
        </div>
        <div class="chatgpt_header">
          <img src={images["chat-gpt.png"]} alt="chatGpt" class="chatgpt" />
          <p>ChatGPT</p>
        </div>
        <div class="get_user_chat">
          <p>
            Can you help me write an email to a{" "}
            <span
              class={
                response.length > 0
                  ? "fill-in-selected disabled"
                  : "fill-in-selected"
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="3"
                height="22"
                viewBox="0 0 3 22"
                fill="none"
                class="fill-in-placeholder__cursor"
              >
                <path
                  d="M1.5 1V21"
                  stroke="#5653FE"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
              </svg>
              {response.length > 0 ? response[0] : "object"}
            </span>
            about{" "}
            <span
              class={
                response.length > 1
                  ? "fill-in-selected disabled"
                  : "fill-in-selected"
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="3"
                height="22"
                viewBox="0 0 3 22"
                fill="none"
                class="fill-in-placeholder__cursor"
              >
                <path
                  d="M1.5 1V21"
                  stroke="#5653FE"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
              </svg>
              {response.length > 1 ? response[1] : "subject"}
            </span>
            ? Include product features
          </p>
        </div>
        {state.check === true && (
          <>
            <div class="customer_chat">
              <img src={images["customer-avatar.svg"]} alt="customer-avatar" />
              <p>
                Can you help me write an email to a client about a recent
                meeting ? Include product features
              </p>
            </div>
            <div class="ai_chat ">
              <img src={images["ai-avatar.svg"]} alt="ai-avatar" />
              <p class="type_text">{displayText}</p>
            </div>
          </>
        )}
      </div>

      {state.check ? (
        <div class="">
          {state.is_res_correct === true ? (
            <div class="ans_popup">
              <div class="popup_header">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                >
                  <rect
                    width="19"
                    height="19"
                    x=".5"
                    y=".5"
                    fill="#19AA32"
                    rx="9.5"
                  ></rect>
                  <rect
                    width="19"
                    height="19"
                    x=".5"
                    y=".5"
                    stroke="#19AA32"
                    rx="9.5"
                  ></rect>
                  <path
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14.666 6.5 8.25 12.917 5.333 10"
                  ></path>
                </svg>
                <p>Great job!</p>
              </div>
              <div class="popup_feedback">
                <p>You're right on track with your approach</p>
              </div>
              <button class="continue_btn" onClick={toContinue}>
                Continue
              </button>
            </div>
          ) : (
            <div class="ans_popup ans_popup_error">
              <div class="popup_header">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                >
                  <rect
                    width="19"
                    height="19"
                    x=".5"
                    y=".5"
                    fill="#D43333"
                    rx="9.5"
                  ></rect>
                  <rect
                    width="19"
                    height="19"
                    x=".5"
                    y=".5"
                    stroke="#D43333"
                    rx="9.5"
                  ></rect>
                  <path
                    fill="#fff"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    d="M6.087 6.087a.583.583 0 0 1 .825 0L10 9.175l3.087-3.088a.583.583 0 1 1 .825.825L10.825 10l3.087 3.087a.583.583 0 1 1-.825.825L10 10.825l-3.088 3.087a.583.583 0 0 1-.825-.825L9.175 10 6.087 6.912a.583.583 0 0 1 0-.825Z"
                  ></path>
                </svg>
                <p>Almost there</p>
              </div>
              <div class="popup_feedback">
                <p>Correct answer: client, a recent meeting</p>
              </div>
              <button class="continue_btn" onClick={tryAgain}>
                Try again
              </button>
            </div>
          )}
        </div>
      ) : (
        <div class="userchatInput_container">
          <div>
            <div class="user_chatInput_btn">
              <button
                style={{ zIndex: "1" }}
                onClick={() => fill_the_gap("subject", "a recent meeting")}
              >
                a recent meeting
              </button>
              <button
                style={{ zIndex: "1" }}
                onClick={() => fill_the_gap("object", "client")}
              >
                <div
                  class="click_here"
                  style={{
                    display: state.click_here === true ? "block" : "none",
                  }}
                  onClick={() => setState({ ...state, click_here: false })}
                >
                  <p>Click Here</p>
                </div>
                client
              </button>
              <div
                class="load_background"
                style={{
                  display: state.click_here === true ? "block" : "none",
                }}
              ></div>
            </div>
          </div>
          <div>
            <button
              class={
                response.length === 2
                  ? "check_btn check_btn_active"
                  : "check_btn"
              }
              style={{ cursor: "pointer" }}
              onClick={checkResponse}
            >
              Check
            </button>

            <div class="close_icon disabled">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17.7072 8.29289C18.0977 8.68342 18.0977 9.31658 17.7072 9.70711L15.4143 12L17.7072 14.2929C18.0977 14.6834 18.0977 15.3166 17.7072 15.7071C17.3166 16.0976 16.6835 16.0976 16.293 15.7071L14.0001 13.4142L11.7072 15.7071C11.3166 16.0976 10.6835 16.0976 10.293 15.7071C9.90243 15.3166 9.90243 14.6834 10.293 14.2929L12.5858 12L10.293 9.70711C9.90243 9.31658 9.90243 8.68342 10.293 8.29289C10.6835 7.90237 11.3166 7.90237 11.7072 8.29289L14.0001 10.5858L16.293 8.29289C16.6835 7.90237 17.3166 7.90237 17.7072 8.29289Z"
                  fill="#6B7280"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.0001 5C22.0001 4.44772 21.5523 4 21.0001 4H9.46C8.87615 4 8.32145 4.25513 7.94148 4.69842L2.79863 10.6984C2.15664 11.4474 2.15664 12.5526 2.79863 13.3016L7.94148 19.3016C8.32145 19.7449 8.87615 20 9.46 20H21.0001C21.5523 20 22.0001 19.5523 22.0001 19V5ZM20.0001 18H9.46L4.31714 12L9.46 6H20.0001V18Z"
                  fill="#6B7280"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
