import React from "react";
import images from "../../Assets/Images";
import { useNavigate } from "react-router-dom";

export default function OnboardingStart() {
  const navigate = useNavigate();
  return (
    <>
      <div className="new_container">
        <div className="lets_jump_content_box">
          <h2>Let's Jump In!</h2>
          <p>
            Let's start with an overview of your incredible journey with us.
          </p>
          <img src={images["jump-in.png"]} alt="jump-in" className="img_fluid" />
        </div>
      </div>
      <div className="btn_box">
        <button
          className="ready_button"
          onClick={() => navigate("/onboarding/v2/intro-lesson")}
        >
          I'm ready
        </button>
        <button className="skip_btn" onClick={() => navigate("/")}>
          Skip for now
        </button>
      </div>
    </>
  );
}
