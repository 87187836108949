import React from 'react';
import images from '../../Assets/Images';
import { useNavigate } from 'react-router-dom';

export default function Congrats() {
    const navigate = useNavigate();
  return (
    <div class="congrats-page-wrapper">
    <button type="button" class="congrats_close_button" onClick={()=>navigate("/")}>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
            <path fill="#565B66" d="M9.293 9.293a1 1 0 0 1 1.414 0L16 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414L17.414 16l5.293 5.293a1 1 0 0 1-1.414 1.414L16 17.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L14.586 16l-5.293-5.293a1 1 0 0 1 0-1.414Z">
            </path>
        </svg>
    </button>

    <div class="congrats-page">
        <div></div>
        <div class="center_img_box">
            <img src={images["complete.svg"]} alt="complete" class="img_fluid"/>
            <h3>Congratulations!</h3>
            <p>Keep up the practice to maintain your progress!</p>
        </div>
        <div class="prompts_discovered">
            <h4>Prompts discovered:</h4>
            <div class="email_box">
                <h5>Email</h5>
                <p>"Write an email to [recipient] to [purpose]. Include product features."</p>

                <div class="chatgpt_icon"><img src={images["chat-gpt.png"]} width="24" height="24" alt="chat-gpt"/>
                    <p>ChatGPT</p>
                </div>
                <div class="email_tag">
                    <span>Writing Assistance</span>
                    <span>Email</span>
                </div>
            </div>
            <button class="ready_button" onClick={()=>navigate('/')}>
                Continue
            </button>

        </div>
    </div>
</div>
  )
}
