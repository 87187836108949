import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export default function AuthUser() {
  const navigate = useNavigate();
  let BASE_URL = "https://api.successpilot.ai/api/v1";

  if (process.env.NODE_ENV === "development") {
    BASE_URL = "http://localhost:8000/api/v1"; //process.env.BASE_URL;
  } else if (process.env.NODE_ENV === "production") {
    BASE_URL = "https://api.successpilot.ai/api/v1";
  }
  const getToken = () => {
    const tokenString = localStorage.getItem("token");
    return tokenString;
  };

  const getUser = () => {
    // const userString = sessionStorage.getItem("user");
    const userString = localStorage.getItem("user");

    if (userString) {
      return JSON.parse(userString);
    }

    return userString;
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());

  const saveToken = (user, token) => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", token);
    setToken(token);

    setUser(user);
    if (user.isplanActive === false) {
      navigate("/profile/subscription-plan");
    } else {
      navigate("/");
    }
  };

  const http = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-type": "application/json",
      accessToken: `Bearer ${token}`,
    },
  });

  const logout = () => {
    localStorage.clear();
    navigate("/auth/signin");
  };
  const errorHandle = (err) => {
    /**
     * status=3 token expire
     */
    console.log(err.response.data);
    if (err.response.data.status === 0) {
      logout();
    }
    if (err.response.data.status === 3) {
      logout();
    }
  };
  return {
    setToken: saveToken,
    token,
    user,
    getToken,
    http,
    logout,
    errorHandle,
    BASE_URL
  };
}
