import React, { useState, useEffect, useRef } from "react";
import Auth from "../../helpers/Auth";
import Chunk from "./content/Chunk";
import Quiz from "./content/Quiz";
import FillTheGap from "./content/FillTheGap";
import { useNavigate } from "react-router-dom";
import PreLoader from "../PreLoader";

export default function IntroLesson() {
  const { http, user } = Auth();
  const navigate = useNavigate();
  const endOfListRef = useRef(null);
  const [state, setState] = useState({
    step: 0,
    toContinue: true,
  });

  const [onboarding, setOnboarding] = useState();
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    http
      .get(`/onboarding-ai?details=true`)
      .then((res) => {
        console.log(res.data.data);

        setOnboarding(res.data.data);
        setCurrentData([
          ...currentData,
          res.data.data.onboarding_ai_tasks[state.step],
        ]);
        setState({
          ...state,
          content_length: res.data.data.onboarding_ai_tasks.length,
          progress: 100 / res.data.data.onboarding_ai_tasks.length,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const nextContent = () => {
    if (state.content_length - 1 > state.step) {
      setTimeout(() => {
        endOfListRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
      // console.log(onboarding.onboarding_ai_tasks[state.step + 1]);
      if (state.step < 5) {
        setCurrentData([
          ...currentData,
          onboarding.onboarding_ai_tasks[state.step + 1],
        ]);
      } else {
        setCurrentData([onboarding.onboarding_ai_tasks[state.step + 1]]);
      }

      setState({
        ...state,
        step: state.step + 1,
        progress: ((state.step + 2) * 100) / state.content_length,
      });
    } else {
      // console.log("end");
    }
  };

  return (
    <>
      {currentData ? (
        <>
          <div className="lesson_header_onboarding">
            <button onClick={() => navigate("/")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="none"
              >
                <path
                  fill="#565B66"
                  d="M9.293 9.293a1 1 0 0 1 1.414 0L16 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414L17.414 16l5.293 5.293a1 1 0 0 1-1.414 1.414L16 17.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L14.586 16l-5.293-5.293a1 1 0 0 1 0-1.414Z"
                ></path>
              </svg>
            </button>
            <div className="lesson-progress">
              <div
                className="lesson-line"
                style={{ width: Math.round(state.progress) + "%" }}
              ></div>
            </div>
          </div>
          <div className="new_container">
            <div className="lesson_content_box">
              {currentData.map((el) => {
                return (
                  <div key={el.id}>
                    {el.type === "chunk" && <Chunk chunk={el.content} />}
                    {el.type === "quiz" && <Quiz chunk={el.content} />}
                    {el.type === "fill_the_gap" && (
                      <FillTheGap chunk={el.content} />
                    )}
                  </div>
                );
              })}

              <div ref={endOfListRef}></div>
            </div>
          </div>

          <div className="btn_box">
            {state.step < state.content_length - 1 && (
              <button
                className={state.toContinue ? "ready_button" : "button_box"}
                disabled={!state.toContinue}
                onClick={nextContent}
              >
                Continue
              </button>
            )}
          </div>
        </>
      ) : (
        <PreLoader />
      )}
    </>
  );
}
