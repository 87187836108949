import { useState,useEffect } from "react";


export default function Quiz({chunk}) {
    const [quizContent, setQuizContent] = useState();

    const [quizState, setQuizState] = useState({
      submit: false,
      hint: false,
      selected: false,
    });
    useEffect(() => {
      let content = "";

      if (typeof chunk === "object") {
        content = chunk[0];
      } else {
        content = JSON.parse(chunk)[0];
      }
      console.log(content);
      setQuizContent(content);
    }, []);

    const selectAnswer = (e) => {
      if (quizState.submit) return;
      setQuizState({
        ...quizState,
        selectOption: e.target.value,
        selected: true,
      });
    };
    const submit = () => {
      setQuizState({
        ...quizState,
        submit: true,
      });
    };
    return (
      <div className="guide-lesson-quizzes">
        <h4>{quizContent && quizContent.text}</h4>

        <ul
        // className={
        //   quizState.submit === true &&
        //   (quizState.selectOption === "true" ? "correct_ul" : "incorrect_ul")
        // }
        >
          {quizContent &&
            quizContent.options.map((el, index) => {
              return (
                <li key={el.text}>
                  {" "}
                  <label htmlFor={"yes_" + index} style={{ cursor: "pointer" }}>
                    <input
                      type="radio"
                      id={"yes_" + index}
                      className="quiz-option__radio"
                      name="res"
                      onClick={selectAnswer}
                      value={el.is_correct}
                      disabled={quizState.submit}
                    />

                    <span>{el.text}</span>
                  </label>
                </li>
              );
            })}
        </ul>

        {quizState.hint && (
          <div class="after_select">
            <p>{chunk.hint}</p>
          </div>
        )}

        {quizState.submit &&
          (quizState.selectOption === "true" ? (
            <div class="anws_result_popup">
              <div class="top">
                <svg
                  class="quiz-checkmark-correct"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  fill="none"
                >
                  <rect
                    width="19"
                    height="19"
                    x=".5"
                    y="1"
                    fill="currentColor"
                    rx="9.5"
                  ></rect>
                  <path
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14.667 7 8.25 13.417 5.333 10.5"
                  ></path>
                </svg>
                <svg
                  class="quiz-checkmark-incorrect"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                >
                  <rect
                    width="19"
                    height="19"
                    x=".5"
                    y=".5"
                    fill="currentColor"
                    rx="9.5"
                  ></rect>
                  <path
                    fill="#fff"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    d="M6.088 6.088a.583.583 0 0 1 .824 0L10 9.175l3.088-3.087a.583.583 0 0 1 .825.824L10.825 10l3.088 3.088a.583.583 0 0 1-.825.825L10 10.825l-3.088 3.088a.583.583 0 1 1-.824-.825L9.175 10 6.088 6.912a.583.583 0 0 1 0-.824Z"
                  ></path>
                </svg>
                <h3>Correct answer</h3>
              </div>
              <p>{quizContent && quizContent.correct_text}</p>
            </div>
          ) : (
            <div class="anws_result_popup incorrect">
              <div class="top">
                <svg
                  class="quiz-checkmark-correct"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  fill="none"
                >
                  <rect
                    width="19"
                    height="19"
                    x=".5"
                    y="1"
                    fill="currentColor"
                    rx="9.5"
                  ></rect>
                  <path
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14.667 7 8.25 13.417 5.333 10.5"
                  ></path>
                </svg>
                <svg
                  class="quiz-checkmark-incorrect"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                >
                  <rect
                    width="19"
                    height="19"
                    x=".5"
                    y=".5"
                    fill="currentColor"
                    rx="9.5"
                  ></rect>
                  <path
                    fill="#fff"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    d="M6.088 6.088a.583.583 0 0 1 .824 0L10 9.175l3.088-3.087a.583.583 0 0 1 .825.824L10.825 10l3.088 3.088a.583.583 0 0 1-.825.825L10 10.825l-3.088 3.088a.583.583 0 1 1-.824-.825L9.175 10 6.088 6.912a.583.583 0 0 1 0-.824Z"
                  ></path>
                </svg>
                <h3>Incorrect answer</h3>
              </div>
              <p>{quizContent && quizContent.wrong_text}</p>
            </div>
          ))}

        <div class="button_box">
          <button
            onClick={() =>
              setQuizState({ ...quizState, hint: !quizState.hint })
            }
          >
            Hint
          </button>
          <button onClick={submit} disabled={!quizState.selected}>
            Submit
          </button>
        </div>
      </div>
    );
}
