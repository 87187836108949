import React from "react";
import images from "../../Assets/Images";
import { useNavigate } from "react-router-dom";

export default function OnboardingV2() {
  const navigate = useNavigate();
  return (
    <>
      <div className="new_container">
        <div className="plan_box">
          <div className="top_content">
            <h2>Your Plan is Ready!</h2>
            <p>
              Let's dive into the steps you'll take to become an AI expert and
              integrate these powerful tools into your daily life.
            </p>
          </div>
          <div className="top_content2">
            <h3>AI Mastery</h3>
            <p>28 modules</p>
          </div>
          <div className="img_box">
            <img src={images["steps.png"]} alt="steps" className="img_fluid" />
          </div>
        </div>
      </div>
      <div className="btn_box">
        <button
          className="ready_button"
          onClick={() => navigate("/onboarding/v2/start-onboarding")}
        >
          I'm ready
        </button>
      </div>
    </>
  );
}
