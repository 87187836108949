import React, { useState, useEffect } from "react";
import parse from "html-react-parser";

export default function Chunk({ chunk, pageType }) {
  const [cunkContent, setChunkContent] = useState();
  useEffect(() => {
    let content = "";
    let taskContent = "";

    if (typeof chunk === "object") {
      taskContent = chunk;
    } else {
      taskContent = JSON.parse(chunk);
    }

    taskContent.forEach((el) => {
      if (el.type === "title" || el.type === "heading") {
        content = content + `<h3>${el.value}</h3>`;
      } else if (el.type === "text") {
        content = content + `<p>${el.value}</p>`;
      } else if (el.type === "image") {
        content = content + `<img src=${el.value} alt="start_lesson" />`;
      }
    });
    setChunkContent(content);
  }, [chunk]);

  return (
    <div className="lesson_content">{cunkContent && parse(cunkContent)}</div>
  );
}
